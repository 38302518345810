.select-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    label {
        font-size: $input-label-font-size;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
        z-index: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
    }
}