@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');

* { 
    margin: 0; 
    padding: 0; 
    outline: 0; 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    min-width: 1200px;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

.section-title {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: $text-secondary-color;
    margin: 15px 0;
}

.btn-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #696969;
    margin-top: 5px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #dadada; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(153, 153, 153); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}