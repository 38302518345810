#auth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container {
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px;
    }

    .banner {
        width: calc(100% - 400px);
        height: 100vh;
        background-image: url(../../assets/images/bg.png);
        background-size: cover;
    }
}

