.box-logo {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

#sidebar {
    height: calc(100vh - 49px);
    width: $sidebar-width;
    background-color: $background-sidebar;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn-production {
        border: none;
        border-radius: 3px;
        background-color: #FFF;
        padding: 15px;
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    ul {
        overflow-y: auto;
        li {
            cursor: pointer;
            a {
                position: relative;
                display: flex;
                padding: 20px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                align-items: center;
                transition: .3s;
    
                img {
                    margin-right: 15px;
    
                    &.arrow {
                        position: absolute;
                        right: 15px;
                        transition: .3s;
                    }
                }
            }
    
            ul {
                display: none;
                padding: 10px 0;
                background-color: $secondary-dark-color;
    
                li {
                    a {
                        padding: 10px 20px;
                        .dot {
                            display: table;
                            width: 12px;
                            height: 12px;
                            border: solid 1px $white-color;
                            border-radius: 100%;
                            margin-right: 15px;
                            margin-left: 3px;
                            transition: .3s;
                        }
                    }
    
                    &.active, &:hover {
                        .dot {
                            background-color: $white-color;
                        }
                    }
                }
            }

            &.active {
                .arrow {
                    transform: rotate(-90deg);
                }
                ul {
                    display: block;
                }
            }
        }
    }

    > ul > li {
        &.active, &:hover {
            > a {
                background-color: #151515;
            }
        }
    }

    #user {
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #151515;

        a {
            color: $white-color;
            font-size: 14px;
        }
    }
}