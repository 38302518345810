$box-size: 120px;
$box-margin: 12px;
$box-radius: 2px;

.plan-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: $box-margin;

    > button {
        width: $box-size;
        height: $box-size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ECF0F5;
        border: solid 1px rgb(116, 116, 116);
        border-radius: $box-radius;

        img {
            margin-bottom: 10px;
        }
        span {
            
            font-size: 12px;
            font-weight: normal;

            small {
                font-weight: normal;
                font-size: 10px;
            }
        }
    }

    .plan-image {
        width: $box-size;
        height: $box-size;
        border: dashed 1px #ccc;
        border-radius: $box-radius;
        padding: 5px;
        position: relative;

        > button {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: white;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: none;
            border-radius: 2px 0 2px 2px;
            box-shadow: -3px 4px 5px 0px rgb(0 0 0 / 8%);
            img {
                width: 15px;
                pointer-events: none;
            }
        }

        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}