.box-tabs {
    margin-bottom: $elements-margin;
    height: calc(100% - 14%);
    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-left: solid 1px $box-border-color;
        border-right: solid 1px $box-border-color;

        
        button {
            color: $text-secondary-color;
            padding: 15px;
            flex: 1;
            border: none;
            border-bottom: solid 1px $box-border-color;
            border-top: solid 1px $box-border-color;
            background-color: $white-color;

            &.active {
                color: $action-color;
                border-bottom: solid 1px $white-color;
                border-top-color: $action-color;
                box-shadow: inset $action-color 0px 3px 0px;
                
                &:not(:first-child) {
                    border-left: solid 1px $box-border-color;
                }

                &:not(:last-child) {
                    border-right: solid 1px $box-border-color;
                }
            }
        }
    }

    > .body {
        background-color: $white-color;
        padding: 15px;
        border: solid 1px $box-border-color;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        height: 100%;
    }

    &.no-padding {
        .body {
            padding: 0;
        }
    }
}